<template>
  <h1 class="title-meeting">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 13.8125C12.75 12.6391 11.7984 11.6875 10.625 11.6875H9.5625C7.21504 11.6875 5.3125 13.59 5.3125 15.9375V19.125C5.3125 21.4725 7.21504 23.375 9.5625 23.375H10.625C11.7984 23.375 12.75 22.4234 12.75 21.25V13.8125ZM24.4375 23.375C26.785 23.375 28.6875 21.4725 28.6875 19.125V15.9375C28.6875 13.59 26.785 11.6875 24.4375 11.6875H23.375C22.2016 11.6875 21.25 12.6391 21.25 13.8125V21.25C21.25 22.4234 22.2016 23.375 23.375 23.375H24.4375ZM17 0C7.51586 0 0.304141 7.89105 0 17V18.0625C0 18.6495 0.475469 19.125 1.0625 19.125H2.125C2.71203 19.125 3.1875 18.6495 3.1875 18.0625V17C3.1875 9.38387 9.38387 3.1875 17 3.1875C24.6161 3.1875 30.8125 9.38387 30.8125 17H30.8045C30.8098 17.1614 30.8125 28.0048 30.8125 28.0048C30.8125 29.5554 29.5554 30.8125 28.0048 30.8125H21.25C21.25 29.0521 19.8229 27.625 18.0625 27.625H15.9375C14.1771 27.625 12.75 29.0521 12.75 30.8125C12.75 32.5729 14.1771 34 15.9375 34H28.0048C31.3159 34 34 31.3159 34 28.0048V17C33.6959 7.89105 26.4841 0 17 0Z" />
    </svg>
    {{title}}
  </h1>
</template>

<script>
export default {
  name: "TitleMeeting",
  props: ['title']
}
</script>
