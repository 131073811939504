<script>
export default {
  name: "ProgressBar",
  props: ['consumption', 'total'],
  data() {
    return {
      bar: null
    }
  },
  mounted() {
      var ProgressBar = require('progressbar.js')

      this.bar = new ProgressBar.Line('#progress', {
        strokeWidth: 2,
        easing: 'easeInOut',
        duration: 1400,
        color: '#FFB300',
        trailColor: '#F3F4FB',
        trailWidth: 2,
        svgStyle: {
          width: '100%',
          height: '100%',
          'border-radius': '10px',
          border: '0.5px solid var(--color--dark-grey)',
          padding: '1px'
        }
      });

      this.bar.animate(this.rate);  // Number from 0.0 to 1.0
  },
  unmounted() {
    this.bar.destroy()
  },
  updated() {
    this.bar.animate(this.rate);
  },
  computed: {
    rate() {
      return this.consumption / this.total
    }
  },
  methods: {}
}
</script>

<template>
  <div class="progress-text">{{this.consumption}} / {{this.total}} coachings</div>
  <div ref="progress" id="progress"></div>
</template>

<style>
  #progress {
    height: 14px;
  }

  .progress-text {
    margin-bottom: 10px;
    text-align: center;
  }
</style>
