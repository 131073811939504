<script>
import boostPackService from '@/services/boostPack.service';
import TitleMeeting from "@/components/Meeting/TitleMeeting.vue";
import ProgressBar from "@/components/General/ProgressBar.vue";
import moment from "moment/moment";
import meetingsService from "@/services/meetings.service";
import Toastify from "toastify-js";
// import store from "../../store";

export default {
  name: "BoostPackDashboard",
  components: {TitleMeeting, ProgressBar},
  created() {
    this.getCurrentBoostPack();
  },
  data() {
    return {
      boostPack: null
    };
  },
  computed: {
    description() {
      if (this.boostPack && typeof this.boostPack.description !== 'undefined') {
        return (this.boostPack.description + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>');
      }
      return '';
    }
  },
  methods: {
    generateAvatarSrcPath(pathToImg) {
      return pathToImg ? process.env.VUE_APP_API_IMG_AVATAR_URL + '/' + pathToImg : null;
    },
    cancelMeeting(id) {
      meetingsService.cancelMeeting(id)
        .then(() => {
          Toastify({
            text: "Rendez-vous annulé",
            className: "success",
          }).showToast();
          this.getCurrentBoostPack()
          this.boostPack.next_meeting = null
        });
    },
    formatedDate(date) {
      return moment(date).format('dddd Do MMMM').toLowerCase()
    },
    formatedHour(date) {
      return moment(date).format('HH:mm').toLowerCase()
    },
    getCurrentBoostPack() {
      boostPackService.getCurrentBoostPack().then((response) => {
        this.boostPack = response.data.data.boost_pack
      })
    }
  }
}
</script>

<template>
  <h1>Votre parcours de coaching </h1>
  <div v-if="boostPack" class="dashboard">
    <ProgressBar :consumption="boostPack.boost_consumption" :total="boostPack.boost_quantity" />
  </div>
  <div class="description" v-if="description">
    <h3>Votre Objectif</h3>
    <h2 v-html="description"></h2>
  </div>
    <div v-if="boostPack && boostPack.boost_quantity - boostPack.boost_consumption !== 0" class="call-detail dashboard-detail">
      <div v-if="boostPack && boostPack.next_meeting">
        <img src="@/assets/images/note-icon.png" />
        <div>
            <span>Prochain rendez-vous</span>
            <p>{{ formatedDate(boostPack.next_meeting.starts_at) }}</p>
            <p>{{ formatedHour(boostPack.next_meeting.starts_at) }}</p>
            <p>Numéro : {{ boostPack.next_meeting.phone_number }}</p>
            <p>Conférence : {{ boostPack.next_meeting.room_number }}</p>
            <a class="cancel-meeting" href="#" @click.prevent="cancelMeeting(boostPack.next_meeting.id)">Annuler mon rendez-vous</a>
        </div>
      </div>
      <div v-else>
        <img width="50" src="@/assets/images/red-cross.png" />
        <div>
            <span>Attention</span>
            Vous n’avez pas de prochain rendez-vous.
        </div>
      </div>
      <div v-if="boostPack">
        <div v-if="boostPack.next_meeting">
          <TitleMeeting style="font-size: 17px;margin-top: 0;" title="Rendez-vous confirmé avec :"/>
          <div class="right-container">
            <img v-if="this.generateAvatarSrcPath(boostPack.coach.avatar.name)" :src="this.generateAvatarSrcPath(boostPack.coach.avatar.name)" alt="coach-avatar">
            <img v-else src="@/assets/images/avatar.png" alt="coach-avatar">
            <div>{{ boostPack.coach.first_name }} {{ boostPack.coach.last_name }}</div>
          </div>
          <router-link v-if="boostPack && boostPack.boost_consumption !== 0" :disabled="boostPack.next_meeting" class="cta-coach" :to="{ name: 'BoostPackCoachSelection' }">
            Changer mon coach
          </router-link>
        </div>
        <div style="display: flex;align-items: center; flex-direction: column" v-else>
          <div style="display: flex;align-items: center; ">
            <svg width="34" height="34" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
              <path fill="#FFB300"
                    d="M19.875 21.5312C19.875 19.7021 18.3916 18.2188 16.5625 18.2188H14.9062C11.247 18.2188 8.28125 21.1845 8.28125 24.8438V29.8125C8.28125 33.4718 11.247 36.4375 14.9062 36.4375H16.5625C18.3916 36.4375 19.875 34.9541 19.875 33.125V21.5312ZM38.0938 36.4375C41.753 36.4375 44.7188 33.4718 44.7188 29.8125V24.8438C44.7188 21.1845 41.753 18.2188 38.0938 18.2188H36.4375C34.6084 18.2188 33.125 19.7021 33.125 21.5312V33.125C33.125 34.9541 34.6084 36.4375 36.4375 36.4375H38.0938ZM26.5 0C11.7159 0 0.474102 12.3008 0 26.5V28.1562C0 29.0713 0.741172 29.8125 1.65625 29.8125H3.3125C4.22758 29.8125 4.96875 29.0713 4.96875 28.1562V26.5C4.96875 14.6278 14.6278 4.96875 26.5 4.96875C38.3722 4.96875 48.0312 14.6278 48.0312 26.5H48.0188C48.0271 26.7515 48.0312 43.6546 48.0312 43.6546C48.0312 46.0717 46.0717 48.0312 43.6546 48.0312H33.125C33.125 45.2871 30.9004 43.0625 28.1562 43.0625H24.8438C22.0996 43.0625 19.875 45.2871 19.875 48.0312C19.875 50.7754 22.0996 53 24.8438 53H43.6546C48.8159 53 53 48.8159 53 43.6546V26.5C52.5259 12.3008 41.2841 0 26.5 0Z"/>
            </svg>
            <router-link  class="meeting-button" :to="(!boostPack.coach ? { name: 'BoostPackCoachSelection' } : (boostPack && boostPack.boost_consumption === 0 || boostPack.boost_quantity - boostPack.boost_consumption <= 1 ? { name: 'BoostPackCriterion' } : { name: 'MeetingCriterias' }))">
              Prendre<br> rendez-vous
            </router-link>
          </div>
          <router-link :class="{ disabled: !boostPack.coach }" style="margin: 10px 0 0 20px;" :to="{ name: 'BoostPackCoachSelection' }">
            Changer mon coach
          </router-link>
        </div>
      </div>
    </div>
</template>
